import { Button, TextField, Form } from '@vitalsource/vstui';
import { emailPattern } from '../utils';
import FormAlert from '../form-alert';
// import { useParams, useLocation } from "react-router-dom";
import { FormattedMessage, useIntl } from 'react-intl';
// import useRecaptcha from "../useRecaptcha";
import PreservedLink from '../PreservedLink';
import styled from 'styled-components';
import { useState } from 'react';

const Styled = {
  FormContainer: styled.div`
    padding: 12px 0 0 0;
  `,
  PasswordTextField: styled(TextField)`
    margin: 0 0 10px 0;
  `,
  ForgotPasswordLink: styled(PreservedLink)`
    float: right;
  `,
  SignInButton: styled(Button)`
    margin: 25px 0 0 0;
  `,
};

const CredentialForm = ({
  error,
  defaultEmail,
  recaptchaRequired,
  onSubmit,
}: {
  error?: string | false;
  defaultEmail?: string;
  recaptchaRequired?: boolean;
  onSubmit: (event: Event, email: string, password: string) => Promise<boolean>;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const { grecaptcha } = useRecaptcha("login-recaptcha");
  // const [recaptchaRequired, setRecaptchaRequired] = useState(false);

  const validateSubmit = (e) => {
    if (isSubmitting) {
      return;
    }

    // @ts-ignore
    // const s = grecaptcha?.execute();
    const email = e.target['user[email]'].value;
    const password = e.target['user[password]'].value;
    if (onSubmit) {
      setIsSubmitting(true);

      return onSubmit(e, email, password).then((success) => {
        if (!success) {
          // if successful, it is redirecting
          setIsSubmitting(false);
        }
        return false;
      });
    } else {
      return true;
    }
  };

  const intl = useIntl();

  return (
    <Styled.FormContainer>
      <Form onSubmit={validateSubmit} id="signin-form" method="post" noValidate>
        {error && (
          <FormAlert level="error" key="signinerror">
            {intl.formatMessage({
              id: 'incorrect-email-password',
              defaultMessage: 'Your email or password is incorrect',
            })}
          </FormAlert>
        )}
        {recaptchaRequired && (
          <FormAlert level="error" key="recaptcharequired">
            {intl.formatMessage({
              id: 'signin.recaptcha',
              defaultMessage: 'Please fill out the recaptcha',
            })}
          </FormAlert>
        )}
        <TextField
          required
          fullWidth
          id="email-field"
          type="email"
          name="user[email]"
          autoComplete="email"
          label={intl.formatMessage({
            id: 'base.email',
            defaultMessage: 'Email Address',
          })}
          defaultValue={defaultEmail}
          pattern={emailPattern}
        />
        <Styled.PasswordTextField
          required
          fullWidth
          id="password-field"
          type="password"
          name="user[password]"
          autoComplete="current-password"
          label={intl.formatMessage({
            id: 'base.password',
            defaultMessage: 'Password',
          })}
        />
        <input
          type="hidden"
          name="recaptcha"
          id="login-recaptcha"
          className="g-recaptcha"
          data-sitekey="_your_site_key_"
          data-callback="onSubmit"
          data-size="invisible"
        />
        <Styled.ForgotPasswordLink
          inline
          to="/forgot"
          data-test-id="forgot-password-link"
        >
          <FormattedMessage
            id="base.forgot-password"
            defaultMessage="Forgot Password"
          />
        </Styled.ForgotPasswordLink>
        <Styled.SignInButton
          fullWidth
          pill
          size="large"
          loading={isSubmitting}
          data-test-id="signin-button"
        >
          <FormattedMessage id="base.signin" defaultMessage="Sign In" />
        </Styled.SignInButton>
      </Form>
    </Styled.FormContainer>
  );
};

export default CredentialForm;
