import { useEffect, useRef } from 'react';
import { useLocale } from '../../locale/IntlProvider';
import styled from 'styled-components';

const Styled = {
  TurnstileContainer: styled.div`
    line-height: 0;
    padding-bottom: 8px;
    height: 0;
    text-align: end;
  `,
};

const Turnstile = () => {
  const turnstileRef = useRef<HTMLDivElement>(null);
  const locale = useLocale();

  useEffect(() => {
    // load turnstile script
    const script = document.createElement('script');
    script.src =
      'https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      // turnstile loaded
      window.turnstile.render('#turnstile-container', {
        sitekey: process.env.REACT_APP_TURNSTILE_SITE_KEY,
        callback: function (token) {
          console.log(`Challenge Success ${token}`);
        },
        appearance: 'interaction-only',
        theme: 'light',
        language: locale,
      });
      if (turnstileRef.current) {
        turnstileRef.current.style.height = 'auto';
      }
    };
    document.body.appendChild(script);
  }, []);

  return (
    <>
      <Styled.TurnstileContainer id="turnstile-container" ref={turnstileRef} />
    </>
  );
};

export default Turnstile;
