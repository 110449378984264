import { Button, TextField, Form } from '@vitalsource/vstui';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import cherry from '@vitalsource/vstui/colors/cherry';
import { numericTwoFactorPinPattern } from '../utils';

const Styled = {
  Container: styled.div`
    position: relative;
    width: 268px;
    overflow: hidden;
  `,
  VerificationInput: styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    pointer-events: none;
    & > div {
      width: 38px;
      input {
        height: 48px;
        font-size: 24px;
        text-align: center;
        &.focused {
          border-width: 2px;
          border-color: #000;
        }
      }
    }
  `,
  FieldSet: styled.fieldset`
    border: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    padding-top: 24px;
  `,
  Legend: styled.legend`
    display: block;
    margin: 0px 0px 7px;
    color: rgb(28, 28, 28);
    font-size: 1.5rem;
    font-weight: 500;
  `,
  InvisibleTextField: styled(TextField)`
    margin-bottom: 8px;
    * {
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      background: transparent !important;
      input {
        color: transparent;
        caret-color: #000;
        letter-spacing: 33px;
        font-size: 24px;
        highlight-color: transparent;
        &::selection {
          background: transparent;
          color: inherit;
        }
      }
    }
  `,
  AuthenticationCodeError: styled.div`
    color: ${cherry.getShade(2)};
  `,
};

const VerificationCodeForm = ({
  onChange,
  error,
}: {
  onChange: (value: string) => void;
  error?: string | false;
}) => {
  const intl = useIntl();
  const invisibleInputRef = useRef<HTMLInputElement>();
  const [value, setValue] = useState('');

  useEffect(() => {
    if (error && invisibleInputRef.current) {
      invisibleInputRef.current?.focus();
      setValue('');
    }
  }, [error]);

  // update the display nodes
  useEffect(() => {
    const inputs = document.querySelectorAll(
      '.display input',
    ) as NodeListOf<HTMLInputElement>;
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].value = value[i] || '';
      inputs[i].classList.remove('focused');
    }
    inputs[value.length]?.classList.add('focused');
    if (value.length === 6) {
      document.querySelectorAll('button')[1]?.focus();
    }
  }, [value]);

  const handleChange = (e) => {
    // each input can only have 1, but if someone pastes in a number, spread it out
    const value = e.target.value;
    // strip non numbers
    setValue(value.replace(/\D/gi, '').slice(0, 6));
    onChange(value);
  };

  return (
    <Styled.Container>
      <Styled.FieldSet aria-hidden="true">
        <Styled.VerificationInput id="2fa-verification-code">
          <TextField aria-hidden="true" className="display"></TextField>
          <TextField aria-hidden="true" className="display"></TextField>
          <TextField aria-hidden="true" className="display"></TextField>
          <TextField aria-hidden="true" className="display"></TextField>
          <TextField aria-hidden="true" className="display"></TextField>
          <TextField aria-hidden="true" className="display"></TextField>
        </Styled.VerificationInput>
      </Styled.FieldSet>
      <Styled.InvisibleTextField
        ref={invisibleInputRef}
        required
        autoFocus
        fullWidth
        id="two-factor-auth-field-search"
        type="text"
        name="twofactor[pin]"
        label={intl.formatMessage({
          id: 'twofactorauthentication.input',
          defaultMessage: 'Authentication Code',
        })}
        size="large"
        value={value}
        onChange={handleChange}
        autoComplete="one-time-code"
        maxLength="6"
        inputmode="numeric"
        aria-describedby="authentication-code-error"
        style={
          {
            //caretColor: value.length === 6 ? 'transparent' : '#000',
          }
        }
      />
      {error && (
        <Styled.AuthenticationCodeError id="authentication-code-error">
          {intl.formatMessage({
            id: 'twofactorauthentication.error',
            defaultMessage: 'Authentication code is incorrect',
          })}
        </Styled.AuthenticationCodeError>
      )}
    </Styled.Container>
  );
};

export default VerificationCodeForm;
