import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import H1 from '../H1';
import P from '../P';
import { Button, Form, TextField } from '@vitalsource/vstui';
import Terms from '../registration/Terms';
import PageViewContainer from '../page-view-container/PageViewContainer';
import HeadingContainer from '../HeadingContainer';
import { useKey } from '../../BrandProvider';
import { useState } from 'react';
import EmailUpdates from '../registration/EmailUpdates';
import Roles from '../registration/Roles';
import { useQuery } from '../useQueryParams';
import useUser from '../../utils/useUser';

const Styled = {
  ContinueButton: styled(Button)`
    margin: 25px 0 0 0;
  `,
  px12Spacer: styled.div`
    padding: 12px 0 0 0;
  `,
};

const Backfill = ({
  showLogo = true,
  logoPath,
  poweredByLogoPath,
  bookInfo,
  // TODO show error on error (could be server error)
  error,
  onSubmit,
}: {
  showLogo?: boolean;
  logoPath?: string;
  emailValue?: string;
  MainHeadingValue?: string;
  poweredByLogoPath?: string;
  bookInfo?: { bookTitle: string; bookAuthor: string; bookCoverUrl: string };
  error?: boolean;
  onSubmit: (
    event: Event,
    eulaAccepted: boolean,
    role?: string,
    emailUpdates?: boolean,
  ) => Promise<boolean>;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showRoleError, setShowRoleError] = useState(false);
  const { referenceUser, roles } = useUser();
  const roleParam = useQuery('role');
  const rolesEnabled = useKey('rolesEnabled', true);
  const defaultRole = rolesEnabled && roleParam ? roleParam : '';
  const [role, setRole] = useState(defaultRole);
  const poweredByEnabled = useKey('poweredByEnabled', true);
  const showBackgroundGraphic = useKey('BackgroundGraphics.enabled', false);
  const backgroundGraphicType = useKey(
    'BackgroundGraphics.background_type',
    'default',
  );
  const showRoles = !referenceUser && rolesEnabled && !roles?.length;

  const intl = useIntl();

  const renderLeftContainerContent = () => {
    return <HeadingContainer bookInfo={bookInfo} />;
  };
  const validateSubmit = (e) => {
    if (isSubmitting) {
      return false;
    }

    const eulaAccepted = !!e.target['user[eula-accepted]']?.checked;
    const emailUpdates = e.target['user[email-updates]']
      ? !!e.target['user[email-updates]']?.checked
      : undefined;

    if (showRoles && !role) {
      e.preventDefault();
      setShowRoleError(true);
      return false;
    }

    if (onSubmit) {
      setIsSubmitting(true);

      return onSubmit(e, eulaAccepted, role, emailUpdates).then((success) => {
        if (!success) {
          // if success, it is redirecting
          setIsSubmitting(false);
        }
        return false;
      });
    } else {
      return true;
    }
  };

  const renderRightContainerContent = () => {
    return (
      <>
        <H1>
          <FormattedMessage
            id={showRoles ? 'backfill.complete-your-account' : 'terms.terms'}
            defaultMessage="Complete Your Account"
          />
        </H1>
        <P>
          <FormattedMessage
            id={
              showRoles ? 'backfill.paragraph-block' : 'terms.paragraph-block'
            }
          />
        </P>
        <Form
          onSubmit={validateSubmit}
          id="backfill-form"
          method="post"
          action="/backfill"
          noValidate
        >
          {showRoles ? (
            <Roles
              onRoleChange={setRole}
              defaultRole={defaultRole}
              roleError={showRoleError}
            />
          ) : null}
          <Styled.px12Spacer />
          <Terms />
          <EmailUpdates />
          <Styled.ContinueButton
            fullWidth
            pill
            size="large"
            loading={isSubmitting}
          >
            <FormattedMessage id="base.continue" defaultMessage="Continue" />
          </Styled.ContinueButton>
        </Form>
      </>
    );
  };

  return (
    <PageViewContainer
      logoPath={logoPath}
      showLogo={showLogo}
      poweredByEnabled={poweredByEnabled}
      poweredByLogoPath={poweredByLogoPath}
      leftContainerContent={renderLeftContainerContent()}
      rightContainerContent={renderRightContainerContent()}
      showBackgroundGraphic={showBackgroundGraphic && !bookInfo}
      backgroundGraphicType={backgroundGraphicType}
      pageTitle={intl.formatMessage({
        id: showRoles ? 'backfill.complete-your-account' : 'terms.terms',
        defaultMessage: 'Complete Your Account',
      })}
    />
  );
};

export default Backfill;
