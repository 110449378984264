import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import H1 from '../H1';
import P from '../P';
import PageViewContainer from '../page-view-container/PageViewContainer';
import HeadingContainer from '../HeadingContainer';
import { useKey } from '../../BrandProvider';
import useMediaQuery, { MediaQueries } from '../../utils/useMediaQuery';
import Instructions from '../Instructions';
import useUser from '../../utils/useUser';
import CredentialForm from './CredentialForm';
import { GrayBlock } from '../styles';
import MergeGraphic from './merge_graphic@2x.png';
import { Button } from '@vitalsource/vstui';
import OrDivider from '../OrDivider';
import { useState } from 'react';
import IdpLogin from '../IdpLogin';
import IdpCompatibilityError from '../IdpCompatibilityError';

const Styled = {
  PresentationGraphicContainer: styled.div`
    text-align: center;
    margin-bottom: 18px;
  `,
  PresentationGraphic: styled.img`
    width: 252px;
  `,
  Button: styled(Button)`
    margin-top: 20px;
  `,
  LoginAnotherWay: styled(Button)`
    text-align: center;
    display: block;
  `,
};

const MergeCredentials = ({
  onMerge,
}: {
  onMerge: (event: Event, email: string, password: string) => Promise<boolean>;
}) => {
  const [social, setSocial] = useState(false);
  const [idpCompatibilityError, setIdpCompatibilityError] =
    useState<boolean>(false);
  const { email } = useUser();

  const renderSupport = () => {
    return (
      <div style={{ fontSize: '1.4rem', textAlign: 'center', marginTop: 28 }}>
        <FormattedMessage
          id="base.having-trouble"
          defaultMessage="Having trouble?"
        />{' '}
        <Button href="/help" inline>
          <FormattedMessage
            id="base.contact-support"
            defaultMessage="Contact Support"
          />
        </Button>
      </div>
    );
  };

  const renderCurrentLogin = (email) => {
    return (
      <P>
        <FormattedMessage
          id="merge.current-login"
          defaultMessage="You are currently logged in."
          values={{
            email: <b>{email}</b>,
          }}
        />
      </P>
    );
  };

  if (social) {
    return (
      <>
        <H1>
          <FormattedMessage
            id="merge.merge-accounts"
            defaultMessage="Merge Accounts"
          />
        </H1>
        {idpCompatibilityError && <IdpCompatibilityError />}
        {renderCurrentLogin(email)}
        <P>
          <FormattedMessage
            id="merge.social-instructions"
            defaultMessage="Choose an existing social account to connect to."
          />
        </P>
        <div style={{ marginTop: 18 }}></div>
        <IdpLogin
          merge={true}
          setIdpCompatibilityError={setIdpCompatibilityError}
        />
        <OrDivider />
        <P>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Styled.LoginAnotherWay inline onClick={() => setSocial(false)}>
              <FormattedMessage
                id="merge.log-in-email-pass"
                defaultMessage="Do you log in with email & password?"
              />
            </Styled.LoginAnotherWay>
          </div>
        </P>
        {renderSupport()}
      </>
    );
  }
  return (
    <>
      <H1>
        <FormattedMessage
          id="merge.merge-accounts"
          defaultMessage="Merge Accounts"
        />
      </H1>
      {renderCurrentLogin(email)}
      <P>
        <FormattedMessage
          id="merge.credential-instructions"
          defaultMessage="Enter merge credentials"
        />
      </P>
      <CredentialForm recaptchaRequired={false} onSubmit={onMerge} />
      <OrDivider />
      <P>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Styled.LoginAnotherWay inline onClick={() => setSocial(true)}>
            <FormattedMessage
              id="merge.log-in-another-way"
              defaultMessage="Do you log in another way?"
            />
          </Styled.LoginAnotherWay>
        </div>
      </P>
      {renderSupport()}
    </>
  );
};

export default MergeCredentials;
