import { useState } from 'react';
import { useKey } from '../BrandProvider';
import { useLocale } from '../locale/IntlProvider';
import SSO from '../pages/sso';
import AccessCreateAccount from '../pages/sso/AccessCreateAccount';
import WelcomeBack from '../pages/welcome-back';
import useUser from '../utils/useUser';
import { useLocation } from 'react-router-dom';
import { relativePathWithParams } from '../utils/url';
import { handleRedirect } from './connector-utils';
import SocialComplete from '../pages/social-complete';
import MergeDecide from '../pages/merge/merge-decide';

const MergeDecideConnector = () => {
  const brandName = useKey('name', 'VitalSource');
  const logoPath = useKey('logo', '');
  const redirectURI = new URL(window.location.href).searchParams.get(
    'redirect_uri',
  );
  const method = new URL(window.location.href).searchParams.get('method');
  const native = new URL(window.location.href).searchParams.get('native');
  const locale = useLocale();
  const [error, setError] = useState<false | string>(false);
  const [existingAccount, setExistingAccount] = useState('');
  const { email, userType } = useUser();
  const location = useLocation();
  const userComplete = location.pathname.indexOf('/user/complete') !== -1;
  const [newAccount, setNewAccount] = useState(
    email && userComplete ? email : '',
  );

  const brandId = new URL(window.location.href).searchParams.get('brand');

  const onMerge = (
    event: Event,
    email: string,
    password: string,
  ): Promise<boolean> => {
    event.preventDefault();

    return fetch(relativePathWithParams('/merge'), {
      method: 'POST',
      headers: { Accept: 'application/json' },
      body: JSON.stringify({
        email,
        password,
        redirectURI,
        method,
      }),
      redirect: 'manual',
    })
      .then((resp: Response) => resp.json())
      .then((resp: any) => {
        if (resp.status < 300) {
          handleRedirect(resp);
          return true;
        } else {
          setError('base.unknown-error');
        }
        return false;
      })
      .catch((e) => {
        setError('base.unknown-error');
        console.log('error', e);
        return false;
      });
  };

  return (
    <MergeDecide
      logoPath={logoPath}
      error={error}
      onMerge={onMerge}
      bookInfo={window._alohomora?.bookInfo}
    />
  );
};

export default MergeDecideConnector;
