import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import H1 from '../H1';
import P from '../P';
import PageViewContainer from '../page-view-container/PageViewContainer';
import HeadingContainer from '../HeadingContainer';
import { useKey } from '../../BrandProvider';
import useMediaQuery, { MediaQueries } from '../../utils/useMediaQuery';
import {
  RadioButtonGroup,
  RadioButton,
  Form,
  Button,
  Checkbox,
} from '@vitalsource/vstui';
import Instructions from '../Instructions';
import useUser from '../../utils/useUser';
import WelcomeBackForm from './CredentialForm';
import { useState } from 'react';
import { relativePathWithParams } from '../../utils/url';
import { GrayBlock } from '../styles';
import FormAlert from '../form-alert';
import { frost } from '@vitalsource/vstui/colors';

const Styled = {
  Button: styled(Button)`
    margin-top: 20px;
  `,
  Checkbox: styled(Checkbox)`
    & > div:first-child {
      padding: 0;
    }
  `,
  RadioButton: styled(RadioButton)`
    margin-top: 8px;
    border-radius: 4px;
    background: ${frost.getShade(1)};
    border: 1px solid ${frost.getShade(3)};
    padding: 11px 13px;
    & > *:first-child {
      height: 0;
      display: block;
    }
    label div {
      display: flex;
      align-items: center;
    }
  `,
};

const Merge = ({
  showLogo = true,
  logoPath,
  accessAccountEnabled = true,
  error = false,
  defaultEmail,
  poweredByLogoPath,
  bookInfo,
  onMerge,
}: {
  showLogo?: boolean;
  logoPath?: string;
  accessAccountEnabled?: boolean;
  error?: string | false;
  defaultEmail?: string;
  poweredByLogoPath?: string;
  bookInfo?: { bookTitle: string; bookAuthor: string; bookCoverUrl: string };
  onMerge: (event: Event, email: string, password: string) => Promise<boolean>;
}) => {
  const poweredByEnabled = useKey('poweredByEnabled', true);
  const accountCompletionInstructions = useKey(
    'accountCompletionInstructions',
    false,
  );
  const brandName = useKey('name', 'VitalSource');
  const customDomains = useKey('accountCompletionEmailDomains', []);
  const isSmallScreen = useMediaQuery(MediaQueries.small);
  const showBackgroundGraphic = useKey('BackgroundGraphics.enabled', false);
  const backgroundGraphicType = useKey(
    'BackgroundGraphics.background_type',
    'default',
  );
  const backgroundGraphicUpload = useKey(
    'BackgroundGraphics.background_upload',
    '',
  );
  const intl = useIntl();
  const { email } = useUser();
  const { email: mergeEmail } = useUser(true);
  const [selectedAccount, setSelectedAccount] = useState<string | undefined>();
  const [confirmMerge, setConfirmMerge] = useState(false);

  const onSubmit = (event: Event) => {
    event.preventDefault();

    console.log('selectedAccount', selectedAccount);
    setConfirmMerge(true);
    return false;
  };

  const renderLeftContainerContent = () => {
    return (
      <HeadingContainer
        headlineKey="base.welcome-to-brand"
        bookInfo={bookInfo}
        instructions={accountCompletionInstructions}
      />
    );
  };
  const renderRightContainerContent = () => {
    if (confirmMerge) {
      return (
        <>
          <H1>
            <FormattedMessage
              id="merge.confirm-account-merge"
              defaultMessage="Confirm Account Merge"
            />
          </H1>
          <P>
            <FormattedMessage
              id="merge.two-emails-together"
              defaultMessage="Merging john.smith@gmail.com into john.smith@institution.edu will merge all data and books together into one account."
              values={{
                email1: <b>{email}</b>,
                email2: <b>{mergeEmail}</b>,
              }}
            />
          </P>
          <P>
            <FormattedMessage
              id="merge.primary-account-access"
              defaultMessage="You can use your primary account to access all your books moving forward. You will no longer be able to log in using john.smith@gmail.com."
              values={{
                email: <b>{selectedAccount}</b>,
              }}
            />
          </P>
          <GrayBlock>
            {selectedAccount === email ? mergeEmail : email}
          </GrayBlock>
          <div style={{ textAlign: 'center' }}>vvv Arrow Icon vvv</div>
          <GrayBlock>{selectedAccount}</GrayBlock>
          <Form action={relativePathWithParams('/merge/decide')} method="POST">
            <input
              type="hidden"
              name="merge-selection"
              value={selectedAccount}
            />
            <Styled.Checkbox
              fullWidth
              name="confirm-merge-checkbox"
              label={
                <FormattedMessage
                  id="base.cannot-be-undone"
                  defaultMessage="I understand can't be undone."
                />
              }
              required
              data-test-id="confirm-merge-checkbox"
            />
            <Styled.Button
              fullWidth
              pill
              size="large"
              id="merge-btn"
              data-test-id="merge-btn"
              type="submit"
            >
              <FormattedMessage
                id="merge.merge-accounts"
                defaultMessage="{merge accounts}"
              />
            </Styled.Button>
            <Styled.Button
              fullWidth
              naked
              color="neutral"
              size="large"
              id="cancel-btn"
              data-test-id="cancel-btn"
              type="button"
            >
              <FormattedMessage id="base.cancel" defaultMessage="Cancel" />
            </Styled.Button>
          </Form>
        </>
      );
    } else {
      return (
        <>
          <H1>
            <FormattedMessage
              id="merge.select-primary-account"
              defaultMessage="Select Primary Account"
            />
          </H1>
          <P>
            <FormattedMessage
              id="merge.all-info-combined"
              defaultMessage="All information will be combined."
              values={{
                email1: <b>{email}</b>,
                email2: <b>{mergeEmail}</b>,
              }}
            />
          </P>
          <Form onSubmit={onSubmit}>
            <RadioButtonGroup
              defaultSelected="radioButton2"
              label={
                <FormattedMessage
                  id="merge.select-primary"
                  defaultMessage="Select Primary"
                />
              }
              onChange={setSelectedAccount}
              selected={selectedAccount}
            >
              <Styled.RadioButton
                groupName="merge-selection"
                id="radio-button-1"
                label={email}
                name="merge-selection"
                value={email}
              />
              <Styled.RadioButton
                groupName="merge-selection"
                id="radio-button-2"
                label={mergeEmail}
                name="merge-selection"
                value={mergeEmail}
              />
            </RadioButtonGroup>
            {selectedAccount && (
              <FormAlert level="warning">
                <b>
                  <FormattedMessage
                    id="merge.merge-warning-review"
                    defaultMessage="Please review before merging"
                  />
                </b>
                <div style={{ marginTop: 10, fontSize: '1.4rem' }}>
                  <P>
                    <FormattedMessage
                      id="merge.merge-warning-recommendation"
                      defaultMessage="We recommend keeping the account that has more books, annotations and flashcards."
                    />
                  </P>
                  <P>
                    <FormattedMessage
                      id="merge.merge-warning-note"
                      defaultMessage="<b>Note:</b> We will do our best, but we cannot guarantee that all annotations & flashcards will transfer over correctly."
                      values={{
                        b: (chunks: any) => <b>{chunks}</b>,
                        a: (chunks: any) => (
                          <a href="" target="_blank" rel="noopener">
                            {chunks}
                          </a>
                        ),
                      }}
                    />
                  </P>
                </div>
              </FormAlert>
            )}
            <Styled.Button
              fullWidth
              pill
              size="large"
              id="continue-btn"
              data-test-id="continue-btn"
              type="submit"
            >
              <FormattedMessage id="base.continue" defaultMessage="Continue" />
            </Styled.Button>
            <Styled.Button
              fullWidth
              naked
              color="neutral"
              size="large"
              id="continue-btn"
              data-test-id="continue-btn"
              type="button"
            >
              <FormattedMessage id="base.cancel" defaultMessage="Cancel" />
            </Styled.Button>
          </Form>
        </>
      );
    }
  };
  const renderAccessAccount = () => {
    if (accessAccountEnabled) {
      return (
        <PageViewContainer
          logoPath={logoPath}
          showLogo={false}
          poweredByEnabled={false}
          poweredByLogoPath={poweredByLogoPath}
          leftContainerContent={renderLeftContainerContent()}
          rightContainerContent={renderRightContainerContent()}
          showBackgroundGraphic={
            showBackgroundGraphic && !accountCompletionInstructions && !bookInfo
          }
          backgroundGraphicType={backgroundGraphicType}
          pageTitle={intl.formatMessage({
            id: 'sso.access-your-content',
            defaultMessage: 'Access Your Content',
          })}
        />
      );
    }
    return null;
  };

  return renderAccessAccount();
};

export default Merge;
