import TwoFactorSetupForm from '../pages/two-factor-auth/setup';
import { useState } from 'react';
import { relativePathWithParams } from '../utils/url';
import { handleRedirect } from './connector-utils';

const TwoFactorAuthSetupConnector = () => {
  const [error, setError] = useState<string | false>(false);

  const onSubmit = (event: Event, pin: string): Promise<boolean> => {
    event.preventDefault();
    setError(false);

    return fetch(relativePathWithParams('/otp/setup'), {
      method: 'POST',
      headers: { Accept: 'application/json' },
      body: JSON.stringify({ otp: pin }),
    })
      .then((resp: Response) => resp.json())
      .then((resp: any) => {
        if (resp.status < 400) {
          handleRedirect(resp);
          return true;
        } else {
          // looks like there's really only one error, so this is just some truthy value
          setError(resp.status);
        }
        return false;
      })
      .catch((e) => {
        setError('');
        return false;
      });
  };

  return (
    <TwoFactorSetupForm
      onSubmit={onSubmit}
      error={error}
      url={window._alohomora.data.uri}
      code={window._alohomora.data.secret}
    />
  );
};

export default TwoFactorAuthSetupConnector;
