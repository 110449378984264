import { FormattedMessage, useIntl } from 'react-intl';
import H1 from '../H1';
import P from '../P';
import PageViewContainer from '../page-view-container/PageViewContainer';
import HeadingContainer from '../HeadingContainer';
import { useKey } from '../../BrandProvider';
import useMediaQuery, { MediaQueries } from '../../utils/useMediaQuery';
import Instructions from '../Instructions';
import useUser from '../../utils/useUser';
import WelcomeBackForm from './CredentialForm';
import MergeLandingPage from './merge-landing-page';
import MergeCredentials from './merge-credentials';
import { useState } from 'react';

const Merge = ({
  showLogo = true,
  logoPath,
  accessAccountEnabled = true,
  error = false,
  defaultEmail,
  poweredByLogoPath,
  bookInfo,
  onMerge,
}: {
  showLogo?: boolean;
  logoPath?: string;
  accessAccountEnabled?: boolean;
  error?: string | false;
  defaultEmail?: string;
  poweredByLogoPath?: string;
  bookInfo?: { bookTitle: string; bookAuthor: string; bookCoverUrl: string };
  onMerge: (event: Event, email: string, password: string) => Promise<boolean>;
}) => {
  const poweredByEnabled = useKey('poweredByEnabled', true);
  const accountCompletionInstructions = useKey(
    'accountCompletionInstructions',
    false,
  );
  const brandName = useKey('name', 'VitalSource');
  const customDomains = useKey('accountCompletionEmailDomains', []);
  const isSmallScreen = useMediaQuery(MediaQueries.small);
  const showBackgroundGraphic = useKey('BackgroundGraphics.enabled', false);
  const backgroundGraphicType = useKey(
    'BackgroundGraphics.background_type',
    'default',
  );
  const backgroundGraphicUpload = useKey(
    'BackgroundGraphics.background_upload',
    '',
  );
  const intl = useIntl();
  const { email } = useUser();
  const [getStarted, setGetStarted] = useState(false);

  const renderLeftContainerContent = () => {
    return (
      <HeadingContainer
        headlineKey="base.welcome-to-brand"
        bookInfo={bookInfo}
        instructions={accountCompletionInstructions}
      />
    );
  };
  const renderRightContainerContent = () => {
    if (getStarted) {
      return <MergeCredentials onMerge={onMerge} />;
    } else {
      return <MergeLandingPage getStarted={() => setGetStarted(true)} />;
    }
  };
  const renderAccessAccount = () => {
    if (accessAccountEnabled) {
      return (
        <PageViewContainer
          logoPath={logoPath}
          showLogo={false}
          poweredByEnabled={false}
          poweredByLogoPath={poweredByLogoPath}
          leftContainerContent={renderLeftContainerContent()}
          rightContainerContent={renderRightContainerContent()}
          showBackgroundGraphic={
            showBackgroundGraphic && !accountCompletionInstructions && !bookInfo
          }
          backgroundGraphicType={backgroundGraphicType}
          pageTitle={intl.formatMessage({
            id: 'sso.access-your-content',
            defaultMessage: 'Access Your Content',
          })}
        />
      );
    }
    return null;
  };

  return renderAccessAccount();
};

export default Merge;
