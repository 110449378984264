import { Button, TextField, Form } from '@vitalsource/vstui';
import { emailPattern } from '../utils';
import FormAlert from '../form-alert';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { useKey } from '../../BrandProvider';
import { useState } from 'react';

const Styled = {
  FormContainer: styled.div`
    padding: 12px 0 0 0;
  `,
  ContinueButton: styled(Button)`
    margin: 12px 0 0 0;
  `,
};

const ActivationForm = ({
  onSubmit,
  errorId,
}: {
  onSubmit: (event: Event, email: string) => Promise<boolean>;
  errorId?: false | string;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const supportUrl = useKey('Support.url', 'https://support.vitalsource.com/');

  const validateSubmit = (e) => {
    if (isSubmitting) {
      return false;
    }

    const email = e.target['email'].value;

    setIsSubmitting(true);

    return onSubmit(e, email).then(() => {
      setIsSubmitting(false);
      return false;
    });
  };

  const intl = useIntl();

  return (
    <Styled.FormContainer>
      <Form
        onSubmit={validateSubmit}
        id="activation-form"
        method="post"
        action="/activate"
        noValidate
      >
        {errorId && (
          <FormAlert level="error" key={errorId}>
            <FormattedMessage
              id={errorId}
              defaultMessage="Activation Error"
              values={{
                a: (chunks) => (
                  <Button
                    inline
                    href={supportUrl}
                    data-test-id="activation-error-support"
                  >
                    {chunks}
                  </Button>
                ),
              }}
            />
          </FormAlert>
        )}
        <TextField
          required
          fullWidth
          id="email-field"
          type="email"
          maxLength={60}
          name="email"
          label={intl.formatMessage({
            id: 'base.email',
            defaultMessage: 'Email Address',
          })}
          pattern={emailPattern}
          autoComplete="email"
        />
        <Styled.ContinueButton
          fullWidth
          pill
          size="large"
          loading={isSubmitting}
          data-test-id="activation-submit-button"
        >
          <FormattedMessage id="base.continue" defaultMessage="Continue" />
        </Styled.ContinueButton>
      </Form>
    </Styled.FormContainer>
  );
};

export default ActivationForm;
