import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import H1 from '../H1';
import P from '../P';
import PageViewContainer from '../page-view-container/PageViewContainer';
import HeadingContainer from '../HeadingContainer';
import { useKey } from '../../BrandProvider';
import useMediaQuery, { MediaQueries } from '../../utils/useMediaQuery';
import Instructions from '../Instructions';
import useUser from '../../utils/useUser';
import WelcomeBackForm from './CredentialForm';
import { GrayBlock } from '../styles';
import MergeGraphic from './merge_graphic@2x.png';
import { Button } from '@vitalsource/vstui';

const Styled = {
  PresentationGraphicContainer: styled.div`
    text-align: center;
    margin-bottom: 18px;
  `,
  PresentationGraphic: styled.img`
    width: 252px;
  `,
  Button: styled(Button)`
    margin-top: 20px;
  `,
};

const MergeLandingPage = ({ getStarted }: { getStarted: () => void }) => {
  const { email } = useUser();
  return (
    <>
      <H1>
        <FormattedMessage
          id="merge.merge-accounts"
          defaultMessage="Merge Accounts"
        />
      </H1>
      <P>
        <GrayBlock>
          <FormattedMessage
            id="merge.current-login"
            defaultMessage="You are currently logged in."
            values={{
              email: <b>{email}</b>,
            }}
          />
        </GrayBlock>
      </P>
      <Styled.PresentationGraphicContainer>
        <Styled.PresentationGraphic src={MergeGraphic} role="presentation" />
      </Styled.PresentationGraphicContainer>
      <P>
        <FormattedMessage
          id="merge.description"
          defaultMessage="Merge description."
        />
      </P>
      <Styled.Button
        fullWidth
        pill
        size="large"
        id="get-started-btn"
        data-test-id="get-started-btn"
        onClick={getStarted}
      >
        <FormattedMessage id="base.get-started" defaultMessage="Get Started" />
      </Styled.Button>
    </>
  );
};

export default MergeLandingPage;
