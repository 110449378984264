import PageViewContainer from '../page-view-container/PageViewContainer';
import { useKey } from '../../BrandProvider';
import { useIntl } from 'react-intl';
import HeadingContainer from '../HeadingContainer';
import TwoFactorAuthSetupForm from './TwoFactorAuthSetupForm';

const TwoFactorAuthSetup = ({
  poweredByLogoPath,
  error = false,
  onSubmit,
  code,
  url,
}: {
  strongPasswordEnabled?: boolean;
  poweredByLogoPath?: string;
  error?: string | false;
  onSubmit: (event: Event, pin: string) => Promise<boolean>;
  code: string;
  url: string;
}) => {
  const signInEnabled = useKey('signinEnabled', true);
  const poweredByEnabled = useKey('poweredByEnabled', true);
  const signinInstructions = useKey('signinInstructions', undefined);
  const showBackgroundGraphic = useKey('BackgroundGraphics.enabled', true);
  const backgroundGraphicType = useKey(
    'BackgroundGraphics.background_type',
    'default',
  );

  const intl = useIntl();

  const renderLeftContainerContent = () => {
    return (
      <HeadingContainer instructions={signInEnabled && signinInstructions} />
    );
  };

  const renderRightContainerContent = () => {
    return (
      <TwoFactorAuthSetupForm
        onSubmit={onSubmit}
        error={error}
        code={code}
        url={url}
      />
    );
  };

  return (
    <PageViewContainer
      poweredByEnabled={poweredByEnabled}
      poweredByLogoPath={poweredByLogoPath}
      leftContainerContent={renderLeftContainerContent()}
      rightContainerContent={renderRightContainerContent()}
      showBackgroundGraphic={showBackgroundGraphic && !signinInstructions}
      backgroundGraphicType={backgroundGraphicType}
      pageTitle={intl.formatMessage({
        id: 'base.signin',
        defaultMessage: 'Sign In',
      })}
    />
  );
};

export default TwoFactorAuthSetup;
