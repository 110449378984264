import { Button, Form, IconButton } from '@vitalsource/vstui';
import Copy from '@vitalsource/vstui-icons/Copy';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { useState } from 'react';
import H1 from '../H1';
import P from '../P';
import QRCode from 'qrcode.react';
import VerificationCodeForm from './VerificationCodeForm';
import cloud from '@vitalsource/vstui/colors/cloud';
import Shield from './shield.svg';

const Styled = {
  FormContainer: styled.div`
    padding: 12px 0 0 0;
  `,
  ContinueButton: styled(Button)`
    margin: 18px 0 12px 0;
  `,
  SkipButton: styled(Button)`
    margin: 8px 0 0 0;
  `,
  HeaderContainer: styled.div`
    margin-bottom: 8px;
    h1 {
      font-size: 2.6rem;
    }
  `,
  QRContainer: styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  `,
  QRImageWrapper: styled.div`
    padding: 8px;
    border: 1px solid ${cloud.getShade(4)};
    img {
      width: 100%;
    }
  `,
  ShieldContainer: styled.div`
    display: flex;
    justify-content: center;
    margin: 12px 0 24px 0;
  `,
  OL: styled.ol`
    margin: 0px;
    padding-left: 24px;
  `,
  Code: styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  `,
};

const TwoFactorAuthSetupForm = (props: {
  error?: string | false;
  onSubmit: (event: Event, pin: string) => Promise<boolean>;
  url: string;
  code: string;
}) => {
  const [isSettingUp, setIsSettingUp] = useState(false);

  const Instructions = () => {
    return (
      <Styled.FormContainer error={props.error}>
        <Styled.HeaderContainer>
          <H1>
            <FormattedMessage
              id="twofactorauthentication.titlesdf"
              defaultMessage="Enable Two-Factor Authentication for Instructor Access "
            />
          </H1>
        </Styled.HeaderContainer>
        <P>
          <FormattedMessage
            id="twofactorauthentication.subtitlesdf"
            defaultMessage="Secure your instructor account and get future access to features like self sampling and publisher recommendations."
          />
        </P>
        <Styled.ShieldContainer>
          <img src={Shield} alt="Shield" role="presentation" />
        </Styled.ShieldContainer>
        <Button
          fullWidth
          pill
          size="medium"
          data-test-id="2fa-submit-button"
          onClick={() => setIsSettingUp(true)}
        >
          <FormattedMessage id="base.set-up" defaultMessage="Set Up" />
        </Button>
        <Styled.SkipButton
          fullWidth
          naked
          color="neutral"
          size="medium"
          href="/"
          data-test-id="2fa-submit-button"
        >
          <FormattedMessage id="base.set-up" defaultMessage="Skip" />
        </Styled.SkipButton>
      </Styled.FormContainer>
    );
  };

  if (isSettingUp) {
    return <Setup {...props} />;
  } else {
    return <Instructions />;
  }
};

const Setup = ({
  error = false,
  onSubmit,
  url,
  code,
}: {
  error?: string | false;
  onSubmit: (event: Event, pin: string) => Promise<boolean>;
  url: string;
  code: string;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [copyButtonLabel, setCopyButtonLabel] = useState('[Copy]');

  const validateSubmit = (e) => {
    if (isSubmitting) {
      return false;
    }

    setIsSubmitting(true);

    return onSubmit(e, verificationCode).then(() => {
      setIsSubmitting(false);
      return false;
    });
  };

  const intl = useIntl();

  const copyCode = () => {
    navigator.clipboard.writeText(code);
    setCopyButtonLabel('[Copied!]');
  };

  return (
    <Styled.FormContainer error={error}>
      <Styled.HeaderContainer>
        <H1>
          <FormattedMessage
            id="twofactorauthentication.title"
            defaultMessage="Two Factor Authentication"
          />
        </H1>
      </Styled.HeaderContainer>
      <Form onSubmit={validateSubmit} id="two-factor-auth-form" noValidate>
        <Styled.QRContainer>
          <Styled.QRImageWrapper>
            <QRCode size={142} title="TODO: Add title" value={url} />
          </Styled.QRImageWrapper>
        </Styled.QRContainer>
        <Styled.OL>
          <li>
            <FormattedMessage
              id="twofactorauthentication.subtitlesdf"
              defaultMessage="Scan the QR code using a supported authenticator app (view supported apps) or enter the code below into the app:"
            />
            <Styled.Code>
              <b>{code}</b>{' '}
              <IconButton
                type="button"
                customIconColor="#000"
                icon={<Copy />}
                onClick={copyCode}
                label={copyButtonLabel}
              />
            </Styled.Code>
          </li>
          <li>
            <P>
              <FormattedMessage
                id="twofactorauthentication.subtitlesdf"
                defaultMessage="Enter the 6 digit verification code shown on the authenticator app:"
              />
            </P>
            <VerificationCodeForm
              onChange={setVerificationCode}
              error={error}
            />
          </li>
        </Styled.OL>
        <Styled.ContinueButton
          fullWidth
          pill
          size="large"
          loading={isSubmitting}
          data-test-id="2fa-submit-button"
        >
          <FormattedMessage id="base.submit" defaultMessage="Submit" />
        </Styled.ContinueButton>
      </Form>
    </Styled.FormContainer>
  );
};

export default TwoFactorAuthSetupForm;
